import React, { useEffect, useState } from "react";
// import * as language from '../i18n.js';

// import logo from '../logo.svg';

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import TransparentContainer from "../Layout/transparent-container.js";
import $ from "jquery";
import WhiteStrip from "../Layout/white-strip.js";
import IconizedText from "../Layout/iconized-text.js";
import ServiceCardsCarousel from "../Layout/service-cards.js";
import IconizedCardContainer from "../Layout/iconized-card-container.js";
import Modal from 'react-modal';

const DataAnalytics = () => {

  const handleSelect = (eventKey) => console.log(`selected ${eventKey}`);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'white',
      borderRadius: '20px',
      zIndex: '100000',
      maxHeight: '80%',
    },
  };

  const openModal = (member) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <body className="smoothscroll enable-animation">
        <div id="wrapper">
          <section className="data-analytics-background" id="slider">
            {/* <div className="overlay dark-6"></div> */}

            <div className="display-table mt-50">
              <div className="display-table-cell vertical-align-middle">
                <div className="container">
                  <div className="smart-tech-container col-md-9 col-12">
                    <h1 className="smart-tech-container-title white-text mb-15">
                      Data Analytics
                    </h1>

                    <p
                      className="white-text mb-20"
                      style={{ lineHeight: "26px", fontWeight: 300 }}
                    >
                      Potenciamos tus decisiones estratégicas a través del análisis profundo de datos, impulsando el éxito de tu negocio con precisión y una visión informada.
                    </p>

                    <TransparentContainer center={false}>
                      <a className="no-href-deco white-text" href="/contacto">
                        Contáctanos
                      </a>
                    </TransparentContainer>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
          >
            {/* <div className="overlay dark-6"></div> */}

            <div>
              <div className="container">
                <h1 className="section-title white-text mb-15 text-center">
                  ¿Cómo podemos ayudarte?
                </h1>

                <div className="container iconized-cards-carousel-container">
                  <div className="row">
                    <IconizedCardContainer items={3}>
                      <div className="col-md-4 col-12 mb-30 g-4 iconized-card">
                        <IconizedText
                          iconName={"data"}
                          text={"Calidad de datos"}
                        />
                        <TransparentContainer width="100%" className={'iconized-card-transparent-container'}>
                          <div style={{ width: "100%" }}>
                            <h4 className="white-text mb-1">
                              <span className="misc-dot">&#183;</span> Mejoramiento de la calidad de los datos para uso sostenible.
                            </h4>
                          </div>
                        </TransparentContainer>
                      </div>

                      <div className="col-md-4 col-12 mb-30 g-4 iconized-card">
                        <IconizedText
                          iconName={"search-normal"}
                          text={"Análisis y Bl"}
                        />
                        <TransparentContainer width="100%" className={'iconized-card-transparent-container'}>
                          <div style={{ width: "100%" }}>
                            <h4 className="white-text mb-1">
                              <span className="misc-dot">&#183;</span> Asesoramiento en la elección y uso de herramientas BI.
                            </h4>
                            <h4 className="white-text mb-1">
                              <span className="misc-dot">&#183;</span>{" "}
                              Asesoramiento en buenas prácticas de utilización.
                            </h4>
                            <h4 className="white-text mb-1">
                              <span className="misc-dot">&#183;</span>{" "}
                              Elección de KPI y desarrollos.
                            </h4>
                          </div>
                        </TransparentContainer>
                      </div>

                      <div className="col-md-4 col-12 mb-30 g-4 iconized-card">
                        <IconizedText
                          iconName={"status-up"}
                          text={"Gestión del cambio"}
                        />
                        <TransparentContainer width="100%" className={'iconized-card-transparent-container'}>
                          <div style={{ width: "100%" }}>
                            <h4 className="white-text mb-1">
                              <span className="misc-dot">&#183;</span> Apoyo en el uso de datos.
                            </h4>
                            <h4 className="white-text mb-1">
                              <span className="misc-dot">&#183;</span> Bl de autoservicio.
                            </h4>
                          </div>
                        </TransparentContainer>
                      </div>
                    </IconizedCardContainer>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <WhiteStrip
            title={
              "Working smarter and faster - while complying legal obligations & developing business opportunities"
            }
          />

          <section className="form-misc-wave">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12 flex-center">
                  <h2 className="white-text section-title">
                    Llena tus datos y <br /> te contactaremos
                  </h2>
                </div>
                <div className="col-md-6 col-12">
                  <form
                    action=""
                    method="post"
                    enctype="multipart/form-data"
                    id="contactForm"
                  >
                    <div class="row">
                      <div class="col-12 mb-20">
                        <input
                          required
                          type="text"
                          class="form-control"
                          name="contact[name]"
                          id="input-name"
                          placeholder="Nombre y apellido"
                        />
                      </div>
                      <div class="col-12 mb-20">
                        <input
                          required
                          type="email"
                          class="form-control"
                          name="contact[email]"
                          id="input-email"
                          placeholder="Correo"
                        />
                      </div>
                      <div class="col-12 mb-20">
                        <input
                          type="position"
                          class="form-control"
                          name="contact[position]"
                          id="input-position"
                          placeholder="Puesto"
                        />
                      </div>

                      <div class="col-12 mb-20">
                        <input
                          type="companyName"
                          class="form-control"
                          name="contact[companyName]"
                          id="input-companyName"
                          placeholder="Nombre de tu empresa"
                        />
                      </div>

                      <div class="col-12 mb-20">
                        <input
                          type="country"
                          class="form-control"
                          name="contact[country]"
                          id="input-country"
                          placeholder="País"
                        />
                      </div>

                      {/* <div class="col-12 mb-20">
                        <select
                          class="form-control pointer"
                          name="contact[userAmnt]"
                          id="input-userAmnt"
                        >
                          <option disabled selected hidden value="">
                            Cantidad de usuarios
                          </option>
                          <option value="1-4">1-4</option>
                          <option value="5-19">5-19</option>
                          <option value="20-99">20-99</option>
                          <option value="100+">100+</option>
                        </select>
                      </div> */}

                      <div class="col-12 mb-20">
                        <textarea
                          maxlength="10000"
                          rows="2"
                          class="form-control"
                          name="contact[message]"
                          id="input-message"
                          placeholder="Mensaje"
                        ></textarea>
                      </div>

                      <div class="col-12 mb-20">
                        <TransparentContainer width="100%">
                          <a className="no-href-deco white-text" href="#" onClick={openModal}>
                            Enviar
                          </a>
                        </TransparentContainer>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        style={customStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              onClick={() => {
                closeModal();
              }}
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 text-center mb-20">
                <i class="fa fa-check-circle-o contact-confirmation-icon fs-200" aria-hidden="true"></i>
              </div>
              <div className="col-12 text-center">
                <h2><b>Enviaste tus datos</b></h2>
                <h3>Nos comunicaremos contigo para esclarecer tus dudas.</h3>
              </div>
            </div>
          </div>
        </div>
      </Modal>

        <a href="#" id="toTop"></a>

        {/* <div id="preloader">
				<div className="inner">
					<span className="loader"></span>
				</div>
			  </div> */}
      </body>
    </div>
  );
};

export default DataAnalytics;
