import React from 'react';
import logo from "./logo.svg";
import "./App.css";
import "./landing-animation.scss";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Home from "./Components/index.js";
import Header from "./Components/Layout/header.js";
import Footer from "./Components/Layout/footer.js";

import EstrategiaEmpresarial from './Components/nuestros-servicios/estrategia-empresarial.js';
import DataAnalytics from './Components/nuestros-servicios/data-analytics.js';
import TecnologiaAplicada from './Components/nuestros-servicios/tecnologia-aplicada.js';
import IndustriasEmergentes from './Components/nuestros-servicios/industrias-emergentes.js';
import NuestroEquipo from './Components/Layout/nuestro-equipo.js';
import Contact from './Components/Layout/contact.js';


function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Header />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/estrategia-empresarial" element={<EstrategiaEmpresarial />} />
          <Route path="/data-analytics" element={<DataAnalytics />} />
          <Route path="/tecnologia-aplicada" element={<TecnologiaAplicada />} />
          <Route path="/industrias-emergentes" element={<IndustriasEmergentes />} />
          <Route path="/nuestro-equipo" element={<NuestroEquipo />} />
          <Route path="/contacto" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default App;
