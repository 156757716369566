import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TransparentContainer from "./transparent-container.js";
import IconizedText from "./iconized-text.js";

const IconizedCardContainer = ({ children, items }) => {
  const options = {
    responsive: {
      0 : {
        items: 1,
        loop: false,
        nav: false,
        rewind: false,
        dots: true,
        autoWidth: false,
        center: true,
      },
      576: {
        items: {items},
        loop: false,
        nav: false,
        rewind: false,
        dots: false,
        margin: 20,
        stagePadding: 40,
        autoWidth: true,
      }
    
  }
};

  const getViewportWidth = () => {
    return document.documentElement.clientWidth;
  };

  return (
    <>
      {getViewportWidth() > 992 ? (
        <>
          {children}
        </>
      ) : (
        <OwlCarousel
          id="iconized-cards-container"
          className="owl-theme"
          {...options}
        >
          {children}
        </OwlCarousel>
      )}
    </>
  );
};

export default React.memo(IconizedCardContainer);
