import React from 'react';
import Modal from 'react-modal';

const NuestroEquipoModal = (props) => {

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-25%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
          background: 'linear-gradient(115.9deg, #365de8ff 0%, #031554ff 100%)',
          borderRadius: '20px',
          zIndex: '100000',
          maxHeight: '80%',
        },
      };

  return (
    <>
      <Modal
        isOpen={props.isModalOpen}
        onRequestClose={props.closeModal}
        contentLabel="Modal"
        style={customStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              onClick={() => {
                props.closeModal();
              }}
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times white-text" aria-hidden="true"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="team-detail-container row">
              <div className="col-md-6 col-12 text-center">
                <img
                  className="leaders-detail-img mb-10"
                  src={`assets/images/weconnect/headshots/${props.member.picture}`}
                  alt=""
                />
                <div className="mb-5">
                  <h4 className="white-text mb-1">{props.member.name}</h4>
                  <p className="white-text fs-11" style={{ fontWeight: 500 }}>
                    {props.member.title}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">

                {props.member.description.map((item) => (
                  <h4 className="white-text mb-8 fs-12">
                    <span className="misc-dot">&#183;</span> {item}
                  </h4>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NuestroEquipoModal;