import React, { useEffect, useState } from "react";
// import * as language from '../i18n.js';

// import logo from '../logo.svg';

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import TransparentContainer from "../Layout/transparent-container.js";
import $ from "jquery";
import WhiteStrip from "../Layout/white-strip.js";
import IconizedText from "../Layout/iconized-text.js";
import ServiceCardsCarousel from "../Layout/service-cards.js";
import IconizedCardContainer from "../Layout/iconized-card-container.js";
import Modal from "react-modal";
import NuestroEquipoModal from "./nuestro-equipo-modal.js";

const NuestroEquipo = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState();

  const openModal = (member) => {
    setIsModalOpen(true);
    setSelectedMember(member);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMember();
  };

  const leaders = [
    {
      id: 0,
      name: "Jessica Torres De La Piedra",
      picture: "jessica-torres.png",
      title: "Practice Leader | Impuestos & Transformación Digital",
      description: [
        "Abogada egresada de la Universidad de Piura (UDEP). Tesis con calificación Excelencia, recientemente ha cursado un programa de especialización de Estrategias de  Negocios y Aplicación de Inteligencia Artificial dictado por UC Berkeley Executive.",
        "Amplia experiencia en procedimientos de fiscalización, implementación de emisión electrónica y proyectos de transformación digital de los departamentos fiscales.",
        "Cuenta con un profundo entendimiento de las nuevas tecnologías y cómo aplicarlas en procesos fiscales.",
        "Se desempeño como ASistente de Cátedra de Impuestos Directos en la Universidad de Lima.",
        "Ponente en eventos nacionales e internacionales.",
        "Asociada del Instituto Peruano de Derecho Tributario (IPDT) y miembro activo de la Asociación Fiscal Internacional (IFA).",
      ],
    },
    {
      id: 1,
      name: "Alejandra De La Piedra",
      picture: "alejandra-piedra.png",
      title: "Practice Leader | Estrategia & Negocios Internacionales",
      description: [
        "Egresada de la Universidad Ricardo Palma en Lima, Perú. Master degree en Management & International Business de la Universidad “Paris-XII” en París, Francia.",
        "Cuenta con certificaciones y un programa de MicroMasters en Negociaciones de la Universidad de Michigan (2022), en “Innovación y Emprendimiento” de la Universidad de Marylan (2021) y “Emprendimiento en Economías Emergentes” de la Universidad de Harvard (2020).",
        "Más de 10 años de experiencia en estrategias de negociación y emprendimiento. Trilingüe y experta en tecnologías comerciales.",
        "Ha liderado la expansión exitosa de pequeñas y medianas empresas francesas en más de 30 paises.",
        "Sólida capacidad para identificar oportunidades y lograr resultados significativos en entornos globales.",
      ],
    },
    {
      id: 2,
      name: "Gustavo Osso",
      picture: "gustavo-osso.png",
      title: "Practice Leader | Tecnología",
      description: [
        "Egresado de la Universidad de Buenos Aires (UBA).",
        "Ingeniero de Sistemas con amplia experiencia en el diseño y programación de soluciones tecnológicas.",
        "Ponente en eventos nacionales e internacionales.",
        "Fundador de Tecdata, empresa encargada del desarrollo de soluciones tecnológicas a grandes empresas latinas.",
      ],
    },
    {
      id: 3,
      name: "Quentin Chotard",
      picture: "quentin-chotard.png",
      title: "Practice Leader | Inteligencia de Negocios",
      description: [
        "Posee un título en Gestión de Sistemas de Información de la Escuela de Negocios de Grenoble. Experto certificado de Microsoft en Power BI (MCSE) y Fundamentos de Azure (AZ900), junto con su certificación en SAFE 5.1.",
        "Más de 8 años de éxito comprobado, Quentin ha demostrado experiencia en el desarrollo de informes de inteligencia empresarial (BI), liderando equipos de datos y gestionando proyectos para una clientela diversa que abarca desde pequeñas hasta grandes empresas.",
        "Sobresale en la elaboración de estrategias digitales y tecnológicas centradas en el valor, con un fuerte enfoque en la innovación, que incluye aprovechar las tecnologías en la nube, implementar soluciones de autoservicio de BI y liderar iniciativas de Datos como servicio.",
        "Su competencia se extiende a través de múltiples industrias, incluyendo Farmacéutica, Bienes Raíces y Seguros, donde ha entregado consistentemente análisis de datos perspicaces e insights accionables.",
        "Su rol abarca toda la cadena de valor de los datos, desde conceptualizar e implementar estrategias de datos hasta supervisar la gesdtión operativa de proyectos, asegurando una ejecución sin problemas y resultados tangibles.",
      ],
      center: true,
    },
    {
      id: 4,
      name: "Karen Campos",
      picture: "karen-campos.png",
      title:
        "Practice Leader | Corporate Legal Affairs &\
      Compliance",
      description: [
        "Abogada por la Pontificia Universidad Católica del Perú cuenta con una  Maestría en Finanzas y  Derecho corporativo por la Escuela de Negocios de la Universidad ESAN y  actualmente es candidata al MBA por la A.B. Freeman School of Business of Tulane University (New Orleans) y CENTRUM.",
        "Especialista en Derecho Corporativo, Societario, Financiero, Mercado de Valores, Fusiones & Adquisiciones, Tributario, Cumplimiento y Gobierno Corporativo, cuenta con especializaciones en Banca de Inversión y estructuración Financiera, Tributación Empresarial; Gestión de Riesgos, Gobierno  e integridad corporativa; y Compliance.",
        "Amplia experiencia asesorando empresas nacionales y transnacionales de distintos sectores económicos, incluyendo aquellas altamente reguladas y supervisadas, con visión estratégica y énfasis en el impacto financiero, tributario y operacional, orientada a la gestión estratégica y mitigación de riesgos legales.",
        "Ha liderado el área corporativa de prestigiosas firmas legales y consultoras empresariales, diseñando estrategias y operaciones corporativas y financieras de gran envergadura por más de USD 600 Millones, orientadas a la expansión empresarial y M&A, incluyendo el aspecto regulatorio, tributario y financiero.Habilidades de negociación, gestión de crisis, análisis crítico, orientación al resultado y creación de soluciones con enfoque en la toma de decisiones estratégicas.",
        "Docente universitaria y coach Ontológica Certificada por la Escuela Internacional de Coaching Ontológico The Newfield Network.",
      ],
    },
  ];

  const boardMembers = [
    {
      id: 0,
      name: " Oswaldo Lozano Byrne",
      picture: "oswaldo-byrne.png",
      title: "Consejero | Estrategia & Servicios Legales",
      description: [
        "Abogado Graduado de la Pontificia Universidad Católica del Perú.",
        "Magíster en Administración de Empresas de la Universidad del Pacífico.",
        "Estudios en Finanzas Corporativas, en Valuación de Bienes y Empresas y Precios de Transferencia.",
        "Admitido al Colegio de Abogados de Lima en 1993",
        "Se ha desempeñado como vocal del Tribunal Fiscal.",
        "Ha sido docente de Pregrado de la Universidad de Piura (UDEP) y Postgrado en la Universidad Nacional de Piura y la Pontificia Universidad Católica del Perú (PUCP).",
        "Miembro del Instituto Peruano de Derecho Tributario y de la Asociación Fiscal Internacional (IFA).",
      ],
      center: true,
      solo: true,
    },
    // {
    //   id: 1,
    //   name: "Annabelle Schweers",
    //   picture: "annabelle-schweers.png",
    //   title: "Practice Leader | Transformación Digital",
    //   description: [
    //     "Máster en Tranformación Digital por la Universidad de Ciencias Aplicadas Hochschule Düsseldorf. Además, posee grado académico en Gestión Turistica de la Universidad de Ciencias Aplicadas Jade Hochschule. Especializada en marketing y gestión de transporte.",
    //     "En cuanto a desarrollo profesional complementario, ha realizado los cursos de Scrum Master profesional y Product Owner asociado a Gestión de Proyectos Internacionales (IPMA).",
    //     "Muchos años de experiencia profesional en diversos campos de la industria turística (hotelería, agencia de viajes) y operador turístico. Conocimientos en ventas, atención al cliente, gestión de relaciones con los clientes, retención de clientes. Adicionalmente, se ha especializado en el desarrollo y gestión de la ingeniería de requisitos en el ámbito de los sistemas de reservas (tour operando); entre otros del sector turístico.",
    //     "En los úlitmos años, vinculada a la gestión de la experiencia del cliente. Conocimientos en gestión de proyectos, métodos ágiles, pensamiento de diseño.",
    //   ],
    // },
  ];

  const MemberCard = ({ member, onClick }) => {
    return (
      <div
        className={member.solo ? '' : `col-lg-4 ${
          member.center ? "offset-lg-2" : ""
        } col-12 mb-30 g-4 iconized-card`}
      >
        <TransparentContainer
          width="100%"
          className="leader-transparent-container"
        >
          <div
            className="text-center leader-container"
            style={{ width: "100%" }}
          >
            <img
              className="leaders-img mb-10"
              src={`assets/images/weconnect/headshots/${member.picture}`}
              alt=""
            />
            <div className="mb-5">
              <h4 className="white-text mb-1">{member.name}</h4>
              <p className="white-text fs-11" style={{ fontWeight: 500 }}>
                {member.title}
              </p>
            </div>

            <h4
              className="white-text mb-3 cursor-pointer"
              style={{ alignSelf: "end" }}
              onClick={() => {
                onClick(member);
              }}
            >
              Conocer más &nbsp;
              <i class="fa fa-arrow-right white-text"></i>
            </h4>
          </div>
        </TransparentContainer>
      </div>
    );
  };

  const handleSelect = (eventKey) => console.log(`selected ${eventKey}`);

  return (
    <div>
      <body className="smoothscroll enable-animation">
        <div id="wrapper">
          <section>
            {/* <div className="overlay dark-6"></div> */}

            <div className="display-table mt-50">
              <div className="display-table-cell vertical-align-middle">
                <div className="container">
                  <div className="smart-tech-container col-md-9 col-12">
                    <h1 className="smart-tech-container-title white-text mb-15">
                      Somos tu aliado estratégico para la toma de decisiones
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="misc-separator-line mb-10" />

          <section>
            {/* <div className="overlay dark-6"></div> */}

            <div>
              <div><h1 className="section-title white-text mb-15 text-center">
                  Practice Leaders
                </h1></div>
              <div className="container">

                <div className="container iconized-cards-carousel-container">
                  <div className="row">
                    <IconizedCardContainer items={5}>
                      {leaders.map((member) => (
                        <MemberCard
                          key={member.id}
                          member={member}
                          onClick={openModal}
                        />
                      ))}
                    </IconizedCardContainer>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="misc-separator-line mb-10" />

          <section>
            <div>
              <div className="container">
                <h1 className="section-title white-text mb-15 text-center">
                  Advisory Board
                </h1>

                <div className="container iconized-cards-carousel-container">
                  <div className="flex-center">
                    {/*<IconizedCardContainer items={2}>*/}
                      {/* <div className="col-lg-4 offset-lg-2 col-12 mb-30 g-4 iconized-card">
                        <TransparentContainer
                          width="100%"
                          className="leader-transparent-container"
                        >
                          <div
                            className="text-center leader-container"
                            style={{ width: "100%" }}
                          >
                            <img
                              className="leaders-img mb-10"
                              src="assets/images/weconnect/headshots/oswaldo-byrne.png"
                              alt=""
                            />
                            <div className="mb-5">
                              <h4 className="white-text mb-1">
                                Oswaldo Lorenzo Byrne
                              </h4>
                              <p
                                className="white-text fs-11"
                                style={{ fontWeight: 500 }}
                              >
                                Law & Strategy Partner
                              </p>
                            </div>

                            <h4
                              className="white-text mb-3 cursor-pointer"
                              style={{ alignSelf: "end" }}
                            >
                              Conocer más &nbsp;
                              <i class="fa fa-arrow-right white-text"></i>
                            </h4>
                          </div>
                        </TransparentContainer>
                      </div>

                      <div className="col-lg-4 col-12 mb-30 g-4 iconized-card">
                        <TransparentContainer
                          width="100%"
                          className="leader-transparent-container"
                        >
                          <div
                            className="text-center leader-container"
                            style={{ width: "100%" }}
                          >
                            <img
                              className="leaders-img mb-10"
                              src="assets/images/weconnect/headshots/catherine-torres.png"
                              alt=""
                            />
                            <div className="mb-5">
                              <h4 className="white-text mb-1">
                                Catherine Torres de La Piedra
                              </h4>
                              <p
                                className="white-text fs-11"
                                style={{ fontWeight: 500 }}
                              >
                                Corporate communications partner
                              </p>
                            </div>

                            <h4
                              className="white-text mb-3 cursor-pointer"
                              style={{ alignSelf: "end" }}
                            >
                              Conocer más &nbsp;
                              <i class="fa fa-arrow-right white-text"></i>
                            </h4>
                          </div>
                        </TransparentContainer>
                      </div> */}

                      {/*{boardMembers.map((member) => (
                        <MemberCard
                          key={member.id}
                          member={member}
                          onClick={openModal}
                        />
                      ))}
                    </IconizedCardContainer>*/}
                    {boardMembers.map((member) => (
                        <MemberCard
                          key={member.id}
                          member={member}
                          onClick={openModal}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Modal"
          style={customStyles}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => {
                  closeModal();
                }}
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-times white-text" aria-hidden="true"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="team-detail-container row">
                <div className="col-md-6 col-12 text-center">
                  <img
                    className="leaders-detail-img mb-10"
                    src="assets/images/weconnect/headshots/catherine-torres.png"
                    alt=""
                  />
                  <div className="mb-5">
                    <h4 className="white-text mb-1">
                      Catherine Torres de La Piedra
                    </h4>
                    <p className="white-text fs-11" style={{ fontWeight: 500 }}>
                      Corporate communications partner
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <h4 className="white-text mb-1">
                    <span className="misc-dot">&#183;</span> Mejoramiento de la
                    calidad de los datos para uso sostenible.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </Modal> */}
        {selectedMember && (
          <NuestroEquipoModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            member={selectedMember}
          ></NuestroEquipoModal>
        )}

        <a href="#" id="toTop"></a>

        {/* <div id="preloader">
				<div className="inner">
					<span className="loader"></span>
				</div>
			  </div> */}
      </body>
    </div>
  );
};

export default NuestroEquipo;
