import React from 'react';

const IconizedText = ({ iconName, text }) => {
  return (
    <>
      <div className="text-center iconized-text-container">
        <img className='mb-5 iconized-text-icon' src={`assets/images/weconnect/icons/${iconName}.svg`} alt=""/>
        <h4 style={{color: 'white'}}>
          {text}
        </h4>
      </div>
    </>
  );
};

export default IconizedText;