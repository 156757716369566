import React, { useEffect } from "react";
// import * as language from '../i18n.js';

// import logo from '../logo.svg';

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import TransparentContainer from './transparent-container.js';
import { Link } from 'react-router-dom';

import $ from "jquery";

const Header = () => {

  const [mobileMenu, setMobileMenu] = React.useState(false);

  const getLanguage = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get('language');
    // const pathname = window.location.pathname.split('/');
    var ret = language;
    console.log(language);
    if (language === '') {
      ret = navigator.language;
      ret = ret.substring(0, 2);
    } else if (language !== '' && language !== 'en' && language !== 'es') {
      ret = 'en';
    }
    return ret;
  };

  const getLanguageForButton = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get('language');
    console.log(language)
    // const pathname = window.location.pathname.split('/');
    let ret = '';
    if (language === 'en') {
      ret = 'ENG';
    } else if (language === 'es') {
      ret = 'ESP';
    } else {
      ret = 'ESP';
    }

    return ret;
  }

  React.useEffect(() => {
    const currentLanguage = getLanguage();
  }, [])

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     $(window).scroll(function () {
  //       if ($(document).scrollTop() > 0) {
  //         $("#topNav").addClass("small");
  //         $("#headerLogo").addClass("small");
  //         $("#headerLogoContainer").addClass("small");
  //         $("#topMain").addClass("small");
  //         $("#navWrapper").addClass("small");
  //       } else {
  //         $("#topNav").removeClass("small");
  //         $("#headerLogo").removeClass("small");
  //         $("#headerLogoContainer").removeClass("small");
  //         $("#topMain").removeClass("small");
  //         $("#navWrapper").removeClass("small");
  //       }
  //     });
  //   });
  // });

  const handleSelect = (eventKey) => console.log(`selected ${eventKey}`);

  const enableSuccessCasesRedirect = () => {
    let path = window.location.pathname;
    if (path != '/') {
      return true;
    }
  }

  const toggleMobileMenu = () => {
    if (mobileMenu) {
      setMobileMenu(false);
    } else {
      setMobileMenu(true);
    }
  }

  return (
    <div id="header" className="navbar-toggleable-md clearfix">
      <header id="topNav">
        <div className="container nav-container">
          {/* <button
                  className="btn btn-mobile"
                  data-toggle="collapse"
                  data-target=".nav-main-collapse"
                >
                  <i className="fa fa-bars"></i>
                </button> */}

          <Link id="headerLogoContainer" className="logo float-left" to="/">
            <img
              id="headerLogo"
              src="assets/images/weconnect/logo.svg"
              alt=""
            />
          </Link>

          <div className="nav-main" id="navWrapper">
            <Navbar
              expand="lg"
              className={`navBar ${mobileMenu && "nav-open"}`}
            >
              <Container id="navbar-container">
                <Navbar.Toggle
                  aria-controls="responsive-navbar"
                  className="navbar-toggle-btn"
                  onClick={toggleMobileMenu}
                >
                  {mobileMenu ? (
                    <i class="fa fa-times" style={{ color: "white" }}></i>
                  ) : (
                    <i class="fa fa-bars" style={{ color: "white" }}></i>
                  )}
                </Navbar.Toggle>
                <Link
                  id="mobileHeaderLogoContainer"
                  className="logo float-left"
                  to="/"
                >
                  <img
                    id="headerLogo"
                    src="assets/images/weconnect/logo.svg"
                    alt=""
                  />
                </Link>
                <Nav
                  className="me-auto nav-link-container"
                  id="open-mobile-nav-language-btn"
                >
                  {/* <TransparentContainer height="32px"> */}
                  <NavDropdown
                    // className="transparent-container"
                    title={
                      <TransparentContainer height="32px" flexDirection="row">
                        <img
                          className="language-icon"
                          src="assets/images/weconnect/icons/globe.svg"
                          alt=""
                        />
                        {getLanguageForButton()}
                      </TransparentContainer>
                    }
                    id="collapsible-nav-dropdown"
                  >
                    <NavDropdown.Item href="?language=en">EN</NavDropdown.Item>
                    <NavDropdown.Item href="?language=es">ES</NavDropdown.Item>
                  </NavDropdown>
                  {/* </TransparentContainer> */}
                </Nav>
                <Navbar.Collapse id="responsive-navbar">
                  <Nav className="me-auto nav-link-container">
                    <Nav.Link className="nav-link" href="/">
                      Home
                    </Nav.Link>
                    {/* <Nav.Link
                      className="nav-link"
                      href={
                        enableSuccessCasesRedirect()
                          ? "/#successCasesAnchor"
                          : "#successCasesAnchor"
                      }
                    >
                      Nuestros servicios
                    </Nav.Link> */}
                    <NavDropdown
                      // className="transparent-container"
                      title={"Nuestro servicios"}
                      id="nuestros-servicios-dropdown"
                    >
                      <NavDropdown.Item href="/tecnologia-aplicada">
                        Tecnología Aplicada
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/data-analytics">
                        Data Analytics
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/estrategia-empresarial">
                        Estrategia Empresarial
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/industrias-emergentes">
                        Industrias Emergentes
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className="nav-link" href="/nuestro-equipo">
                      Nuestro equipo
                    </Nav.Link>
                    {mobileMenu ? (
                      <TransparentContainer height="32px" >
                        <a className="no-href-deco white-text" href="/contacto">
                          Contáctanos
                        </a>
                      </TransparentContainer>
                    ) : (
                      // <TransparentContainer height="32px">
                      <NavDropdown
                        // className="transparent-container"
                        title={
                          <TransparentContainer height="32px" flexDirection="row">
                            <img
                              className="language-icon"
                              src="assets/images/weconnect/icons/globe.svg"
                              alt=""
                            />
                            {getLanguageForButton()}
                          </TransparentContainer>
                        }
                        id="collapsible-nav-dropdown"
                      >
                        <NavDropdown.Item href="?language=en">
                          EN
                        </NavDropdown.Item>
                        <NavDropdown.Item href="?language=es">
                          ES
                        </NavDropdown.Item>
                      </NavDropdown>
                      // </TransparentContainer>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
