import React from 'react';

const WhiteStrip = ({ title, text, className }) => {
  return (
    <>
      <section className={`heading-title callout-bg ${className}`}>
        <div className="container">
          <div className="text-center">
            <h3 className="fs-30 callout-heading">
              <strong>
                {title}
              </strong>
            </h3>
            <p className="callout-text">
              {text}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhiteStrip;