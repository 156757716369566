import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TransparentContainer from "./transparent-container.js";

const ServiceCardsCarousel = () => {

  const options = {
    responsive: {
      0 : {
        items: 1,
        loop: false,
        nav: false,
        rewind: false,
        dots: true,
        autoWidth: false,
        center: true,
      },
      576: {
        items: 4,
        loop: false,
        nav: false,
        rewind: false,
        dots: false,
        margin: 20,
        stagePadding: 40,
        autoWidth: true,
      }
  }}

  return (
    <OwlCarousel
      id="service-cards-container"
      className="owl-theme"
      {...options}
    >
      <div className="nuestros-servicios-card">
        <TransparentContainer
          height="100%"
          width="100%"
          usePadding={false}
          hideOverflow={true}
          justifyContent={false}
        >
          <div style={{ width: "100%" }}>
            <img
              className="nuestros-servicios-img"
              src="assets/images/weconnect/nuestros-servicios-1.png"
              alt=""
            />
          </div>
          <div style={{ width: "100%", padding: "18px 18px", flex: "1" }}>
            <div className="text-center">
              <h3 className="white-text mb-5">
                Tecnología <br /> Aplicada
              </h3>
            </div>
            <div className="mb-10">
              <p className="fs-14 mb-1">
                <span className="misc-dot">&#183;</span> Diseño y desarrollo de
                soluciones.
              </p>
              <p className="fs-14 mb-1">
                <span className="misc-dot">&#183;</span> Despliegue IA.
              </p>
            </div>
          </div>
          <div style={{ width: "100%", padding: "18px 18px" }}>
            <TransparentContainer width="100%" height="32px">
              <a className="no-href-deco white-text" href="/tecnologia-aplicada">
                Quiero saber más
              </a>
            </TransparentContainer>
          </div>
        </TransparentContainer>
      </div>

      <div className="nuestros-servicios-card">
        <TransparentContainer
          height="100%"
          width="100%"
          usePadding={false}
          hideOverflow={true}
          justifyContent={false}
        >
          <div style={{ width: "100%" }}>
            <img
              className="nuestros-servicios-img"
              src="assets/images/weconnect/nuestros-servicios-2.png"
              alt=""
            />
          </div>
          <div style={{ width: "100%", padding: "18px 18px", flex: "1" }}>
            <div className="text-center">
              <h3 className="white-text mb-5">Data Analytics</h3>
            </div>
            <div className="mb-10">
              <p className="fs-14 mb-1">
                <span className="misc-dot">&#183;</span> Optimización de
                decisiones basadas en datos.
              </p>
            </div>
          </div>
          <div style={{ width: "100%", padding: "18px 18px" }}>
            <TransparentContainer width="100%" height="32px">
              <a className="no-href-deco white-text" href="/data-analytics">
                Quiero saber más
              </a>
            </TransparentContainer>
          </div>
        </TransparentContainer>
      </div>

      <div className="nuestros-servicios-card">
        <TransparentContainer
          height="100%"
          width="100%"
          usePadding={false}
          hideOverflow={true}
          justifyContent={false}
        >
          <div style={{ width: "100%" }}>
            <img
              className="nuestros-servicios-img"
              src="assets/images/weconnect/nuestros-servicios-3.png"
              alt=""
            />
          </div>
          <div style={{ width: "100%", padding: "18px 18px", flex: "1" }}>
            <div className="text-center">
              <h3 className="white-text mb-5">
                Estrategia <br /> Empresarial
              </h3>
            </div>
            <div className="mb-10">
              <p className="fs-14 mb-1">
                <span className="misc-dot">&#183;</span> Investigación de
                mercado.
              </p>
              <p className="fs-14 mb-1">
                <span className="misc-dot">&#183;</span> Desarrollo Mercados
                internacionales.
              </p>
            </div>
          </div>
          <div style={{ width: "100%", padding: "18px 18px" }}>
            <TransparentContainer width="100%" height="32px">
              <a className="no-href-deco white-text" href="/estrategia-empresarial">
                Quiero saber más
              </a>
            </TransparentContainer>
          </div>
        </TransparentContainer>
      </div>

      <div className="nuestros-servicios-card">
        <TransparentContainer
          height="100%"
          width="100%"
          usePadding={false}
          hideOverflow={true}
          justifyContent={false}
        >
          <div style={{ width: "100%" }}>
            <img
              className="nuestros-servicios-img"
              src="assets/images/weconnect/nuestros-servicios-4.png"
              alt=""
            />
          </div>
          <div style={{ width: "100%", padding: "18px 18px", flex: "1" }}>
            <div className="text-center">
              <h3 className="white-text mb-5">
                Industrias <br /> Emergentes
              </h3>
            </div>
            <div className="mb-10">
              <p className="fs-14 mb-1">
                <span className="misc-dot">&#183;</span> Consultorías especializadas.
              </p>
            </div>
          </div>
          <div style={{ width: "100%", padding: "18px 18px" }}>
            <TransparentContainer width="100%" height="32px">
              <a className="no-href-deco white-text" href="/industrias-emergentes">
                Quiero saber más
              </a>
            </TransparentContainer>
          </div>
        </TransparentContainer>
      </div>
    </OwlCarousel>
  );
};

export default React.memo(ServiceCardsCarousel);
