import React, { useEffect, useState } from "react";
// import * as language from '../i18n.js';

// import logo from '../logo.svg';

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import TransparentContainer from './Layout/transparent-container.js';
import $ from "jquery";
import WhiteStrip from "./Layout/white-strip.js";
import IconizedText from "./Layout/iconized-text.js";
import ServiceCardsCarousel from "./Layout/service-cards.js";
import IconizedCardContainer from "./Layout/iconized-card-container.js";

const Home = () => {
  const [contentLoaded, setContentLoaded] = useState(false);

  const handleSelect = (eventKey) => console.log(`selected ${eventKey}`);


  useEffect(() => {
   setContentLoaded(true);
  }, [])

  useEffect(() => {
    if (contentLoaded) {
      var ParticleNetworkAnimation, PNA;
      ParticleNetworkAnimation = PNA = function() {};
      PNA.prototype.init = function(element) {
        this.$el = $(element);
    
        this.container = element;
        this.canvas = document.createElement('canvas');
        this.sizeCanvas();
        this.container.appendChild(this.canvas);
        this.ctx = this.canvas.getContext('2d');
        this.particleNetwork = new ParticleNetwork(this);
    
        this.bindUiActions();
    
        return this;
      };
    
      PNA.prototype.bindUiActions = function() {
        $(window).on('resize', function() {
          // this.sizeContainer();
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.sizeCanvas();
          this.particleNetwork.createParticles();
        }.bind(this));
      };
    
      PNA.prototype.sizeCanvas = function() {
        this.canvas.width = this.container.offsetWidth;
        this.canvas.height = this.container.offsetHeight;
      };
    
      var Particle = function(parent, x, y) {
        this.network = parent;
        this.canvas = parent.canvas;
        this.ctx = parent.ctx;
        this.particleColor = returnRandomArrayitem(this.network.options.particleColors);
        this.radius = getLimitedRandom(1.5, 2.5);
        this.opacity = 0;
        this.x = x || Math.random() * this.canvas.width;
        this.y = y || Math.random() * this.canvas.height;
        this.velocity = {
          x: (Math.random() - 0.5) * parent.options.velocity,
          y: (Math.random() - 0.5) * parent.options.velocity
        };
      };
    
      Particle.prototype.update = function() {
        if (this.opacity < 1) {
          this.opacity += 0.01;
        } else {
          this.opacity = 1;
        }
        // Change dir if outside map
        if (this.x > this.canvas.width + 100 || this.x < -100) {
          this.velocity.x = -this.velocity.x;
        }
        if (this.y > this.canvas.height + 100 || this.y < -100) {
          this.velocity.y = -this.velocity.y;
        }
    
        // Update position
        this.x += this.velocity.x;
        this.y += this.velocity.y;
      };
    
      Particle.prototype.draw = function() {
        // Draw particle
        this.ctx.beginPath();
        this.ctx.fillStyle = this.particleColor;
        this.ctx.globalAlpha = this.opacity;
        this.ctx.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        this.ctx.fill();
      };
    
      var ParticleNetwork = function(parent) {
        this.options = {
          velocity: 1, // the higher the faster
          density: 15000, // the lower the denser
          netLineDistance: 200,
          netLineColor: '#929292',
          particleColors: ['#aaa'] // ['#6D4E5C', '#aaa', '#FFC458' ]
        };
        this.canvas = parent.canvas;
        this.ctx = parent.ctx;
    
        this.init();
      };
    
      ParticleNetwork.prototype.init = function() {
        // Create particle objects
        this.createParticles(true);
    
        // Update canvas
        this.animationFrame = requestAnimationFrame(this.update.bind(this));
    
        this.bindUiActions();
      };
    
      ParticleNetwork.prototype.createParticles = function(isInitial) {
        // Initialise / reset particles
        var me = this;
        this.particles = [];
        var quantity = this.canvas.width * this.canvas.height / this.options.density;
    
        if (isInitial) {
          var counter = 0;
          clearInterval(this.createIntervalId);
          this.createIntervalId = setInterval(function() {
            if (counter < quantity - 1) {
              // Create particle object
              this.particles.push(new Particle(this));
            }
            else {
              clearInterval(me.createIntervalId);
            }
            counter++;
          }.bind(this), 250);
        }
        else {
          // Create particle objects
          for (var i = 0; i < quantity; i++) {
            this.particles.push(new Particle(this));
          }
        }
      };
    
      ParticleNetwork.prototype.createInteractionParticle = function() {
        // Add interaction particle
        this.interactionParticle = new Particle(this);
        this.interactionParticle.velocity = {
          x: 0,
          y: 0
        };
        this.particles.push(this.interactionParticle);
        return this.interactionParticle;
      };
    
      ParticleNetwork.prototype.removeInteractionParticle = function() {
        // Find it
        var index = this.particles.indexOf(this.interactionParticle);
        if (index > -1) {
          // Remove it
          this.interactionParticle = undefined;
          this.particles.splice(index, 1);
        }
      };
    
      ParticleNetwork.prototype.update = function() {
        if (this.canvas) {
    
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.ctx.globalAlpha = 1;
    
          // Draw connections
          for (var i = 0; i < this.particles.length; i++) {
            for (var j = this.particles.length - 1; j > i; j--) {
              var distance, p1 = this.particles[i], p2 = this.particles[j];
    
              // check very simply if the two points are even a candidate for further measurements
              distance = Math.min(Math.abs(p1.x - p2.x), Math.abs(p1.y - p2.y));
              if (distance > this.options.netLineDistance) {
                continue;
              }
    
              // the two points seem close enough, now let's measure precisely
              distance = Math.sqrt(
                Math.pow(p1.x - p2.x, 2) +
                Math.pow(p1.y - p2.y, 2)
              );
              if (distance > this.options.netLineDistance) {
                continue;
              }
    
              this.ctx.beginPath();
              this.ctx.strokeStyle = this.options.netLineColor;
              this.ctx.globalAlpha = (this.options.netLineDistance - distance) / this.options.netLineDistance * p1.opacity * p2.opacity;
              this.ctx.lineWidth = 0.7;
              this.ctx.moveTo(p1.x, p1.y);
              this.ctx.lineTo(p2.x, p2.y);
              this.ctx.stroke();
            }
          }
    
          // Draw particles
          for (var i = 0; i < this.particles.length; i++) {
            this.particles[i].update();
            this.particles[i].draw();
          }
    
          if (this.options.velocity !== 0) {
            this.animationFrame = requestAnimationFrame(this.update.bind(this));
          }
    
        }
        else {
          cancelAnimationFrame(this.animationFrame);
        }
      };
    
      ParticleNetwork.prototype.bindUiActions = function() {
        // Mouse / touch event handling
        this.spawnQuantity = 3;
        this.mouseIsDown = false;
        this.touchIsMoving = false;
    
        this.onMouseMove = function(e) {
          if (!this.interactionParticle) {
            this.createInteractionParticle();
          }
          this.interactionParticle.x = e.offsetX;
          this.interactionParticle.y = e.offsetY;
        }.bind(this);
    
        this.onTouchMove = function(e) {
          e.preventDefault();
          this.touchIsMoving = true;
          if (!this.interactionParticle) {
            this.createInteractionParticle();
          }
          this.interactionParticle.x = e.changedTouches[0].clientX;
          this.interactionParticle.y = e.changedTouches[0].clientY;
        }.bind(this);
    
        this.onMouseDown = function(e) {
          this.mouseIsDown = true;
          var counter = 0;
          var quantity = this.spawnQuantity;
          var intervalId = setInterval(function() {
            if (this.mouseIsDown) {
              if (counter === 1) {
                quantity = 1;
              }
              for (var i = 0; i < quantity; i++) {
                if (this.interactionParticle) {
                  this.particles.push(new Particle(this, this.interactionParticle.x, this.interactionParticle.y));
                }
              }
            }
            else {
              clearInterval(intervalId);
            }
            counter++;
          }.bind(this), 50);
        }.bind(this);
    
        this.onTouchStart = function(e) {
          e.preventDefault();
          setTimeout(function() {
            if (!this.touchIsMoving) {
              for (var i = 0; i < this.spawnQuantity; i++) {
                this.particles.push(new Particle(this, e.changedTouches[0].clientX, e.changedTouches[0].clientY));
              }
            }
          }.bind(this), 200);
        }.bind(this);
    
        this.onMouseUp = function(e) {
          this.mouseIsDown = false;
        }.bind(this);
    
        this.onMouseOut = function(e) {
          this.removeInteractionParticle();
        }.bind(this);
    
        this.onTouchEnd = function(e) {
          e.preventDefault();
          this.touchIsMoving = false;
          this.removeInteractionParticle();
        }.bind(this);
    
        this.canvas.addEventListener('mousemove', this.onMouseMove);
        this.canvas.addEventListener('touchmove', this.onTouchMove);
        this.canvas.addEventListener('mousedown', this.onMouseDown);
        this.canvas.addEventListener('touchstart', this.onTouchStart);
        this.canvas.addEventListener('mouseup', this.onMouseUp);
        this.canvas.addEventListener('mouseout', this.onMouseOut);
        this.canvas.addEventListener('touchend', this.onTouchEnd);
      };
    
      ParticleNetwork.prototype.unbindUiActions = function() {
        if (this.canvas) {
          this.canvas.removeEventListener('mousemove', this.onMouseMove);
          this.canvas.removeEventListener('touchmove', this.onTouchMove);
          this.canvas.removeEventListener('mousedown', this.onMouseDown);
          this.canvas.removeEventListener('touchstart', this.onTouchStart);
          this.canvas.removeEventListener('mouseup', this.onMouseUp);
          this.canvas.removeEventListener('mouseout', this.onMouseOut);
          this.canvas.removeEventListener('touchend', this.onTouchEnd);
        }
      };
    
      var getLimitedRandom = function(min, max, roundToInteger) {
        var number = Math.random() * (max - min) + min;
        if (roundToInteger) {
          number = Math.round(number);
        }
        return number;
      };
    
      var returnRandomArrayitem = function(array) {
        return array[Math.floor(Math.random()*array.length)];
      };
    
    let pna = new ParticleNetworkAnimation();	
    pna.init($('.particle-network-animation')[0]);
    }
    
  }, [contentLoaded]);

  return (
    <div>
      <body className="smoothscroll enable-animation">
        <div id="wrapper">
          <section className="h-750">
            {/* <div className="overlay dark-6"></div> */}
            <div className="h-750 particle-network-animation"></div>
            <div className="display-table">
              <div className="display-table-cell vertical-align-middle">
                <div className="container text-center">
                  <p
                    className="wow fadeInUp landing-title white-text"
                    data-wow-delay="0.7s"
                  >
                    Where <br className="landing-title-word-break"></br>{" "}
                    <b className="white-text">business</b> &{" "}
                    <b className="white-text">law</b> <br /> expertise{" "}
                    <b className="white-text">
                      meets <br /> smart
                    </b>{" "}
                    technology
                  </p>

                  <TransparentContainer>
                    <a className="no-href-deco white-text" href="/contacto">
                      Contáctanos
                    </a>
                  </TransparentContainer>
                </div>
              </div>
            </div>
          </section>

          <section className={`heading-title callout-bg`}>
            <div className="container">
              <div className="text-center">
                <h3 className="callout" style={{ margin: "0" }}>
                  <strong>
                    <span style={{ fontWeight: "normal" }}>Brindamos </span>
                    <span className="callout-span">
                      servicios estratégicos{" "}
                    </span>
                    <span style={{ fontWeight: "normal" }}>
                      para cumplir con
                    </span>{" "}
                    <span className="callout-span">
                      obligaciones legales y de negocios
                    </span>
                  </strong>
                </h3>
                <p className="callout-text">
                  Tenemos una trayectoria multidisciplinaria y multisectorial
                  que nos permite cruzar fronteras y ayudar a nuestros clientes
                  a alcanzar el éxito.
                </p>
              </div>
            </div>
          </section>

          <section className="no-padding trasnparent-misc-wave">
            <div>
              <article className="row" style={{ flexGrow: "1" }}>
                <div className="smart-tech-container col-md-6 col-12">
                  <h3 className="smart-tech-container-title white-text mb-20">
                    Usamos tecnología <br />
                    inteligente
                  </h3>

                  <p className="smart-tech-container-paragraph white-text fs-14 mb-10">
                    Nuestro{" "}
                    <b className="white-text">
                      hub de desarrollo de alto rendimiento
                    </b>{" "}
                    diseña, desarrolla y despliega soluciones ágiles a medida.
                  </p>

                  <p className="smart-tech-container-paragraph white-text fs-14">
                    Usamos servicios cognitivos (modelos pre-entrenados de
                    inteligencia artificial) acorde al objetivo requerido.
                  </p>
                </div>

                <div className="col-md-6 col-12">
                  <img
                    className="smart-tech-container-img"
                    src="assets/images/weconnect/phone-with-icons.png"
                    alt=""
                  />
                </div>
              </article>
            </div>
          </section>

          <section>
            <div className="container iconized-cards-carousel-container">
              <div className="row">
                <IconizedCardContainer items={3}>
                  <div className="col-md-4 col-12 mb-30 g-4 iconized-card">
                    <IconizedText
                      iconName={"star"}
                      text={"Nuestro compromiso"}
                    />
                    <TransparentContainer width="100%" height={200}>
                      <div style={{ width: "100%" }}>
                        <h4 className="white-text mb-1">
                          <span className="misc-dot">&#183;</span> Ser socios
                          estratégicos
                        </h4>
                        <p className="fs-14 mb-7 white-text">
                          Interiorizamos las necesidades de nuestros clientes.
                        </p>
                      </div>
                      <div style={{ width: "100%" }}>
                        <h4 className="white-text mb-1">
                          <span className="misc-dot">&#183;</span> Servicios de
                          alto valor
                        </h4>
                        <p className="fs-14 mb-7 white-text">
                          Transferimos conocimientos especializados.
                        </p>
                      </div>
                    </TransparentContainer>
                  </div>

                  <div className="col-md-4 col-12 mb-30 g-4 iconized-card">
                    <IconizedText
                      iconName={"status-up"}
                      text={"Nuestro enfoque"}
                    />
                    <TransparentContainer width="100%" height={200}>
                      <div style={{ width: "100%" }}>
                        <h4 className="white-text mb-1">
                          <span className="misc-dot">&#183;</span> Know How de
                          alto valor
                        </h4>
                        <p className="fs-14 mb-7 white-text">
                          Equipo con alto conocimiento de la industria.
                        </p>
                      </div>
                      <div style={{ width: "100%" }}>
                        <h4 className="white-text mb-1">
                          <span className="misc-dot">&#183;</span> Tecnología
                          inteligente
                        </h4>
                        <p className="fs-14 mb-7 white-text">
                          Tecnología de vanguardia a disposición del negocio.
                        </p>
                      </div>
                    </TransparentContainer>
                  </div>

                  <div className="col-md-4 col-12 mb-30 g-4 iconized-card">
                    <IconizedText
                      iconName={"tick-circle"}
                      text={"Beneficios"}
                    />
                    <TransparentContainer width="100%" height={200}>
                      <div style={{ width: "100%" }}>
                        <h4 className="white-text mb-1">
                          <span className="misc-dot">&#183;</span> Clientes
                          satisfechos
                        </h4>
                        <p className="fs-14 mb-7 white-text">
                          Satisfacción garantizada.
                        </p>
                      </div>
                      <div style={{ width: "100%" }}>
                        <h4 className="white-text mb-1">
                          <span className="misc-dot">&#183;</span> Soluciones
                          integrales
                        </h4>
                        <p className="fs-14 mb-7 white-text">
                          Abordamos todas las necesidades en un solo lugar.
                        </p>
                      </div>
                    </TransparentContainer>
                  </div>
                </IconizedCardContainer>
              </div>
            </div>
          </section>

          {/* <section className="container service-cards-carousel-container">
            <div className="text-center">
              <h3
                className="white-text callout"
                style={{ fontWeight: "500" }}
              >
                Working smarter and faster - while complying legal obligations &
                developing business opportunities
              </h3>
            </div>
          </section> */}

          <WhiteStrip
            title={
              "Working smarter and faster - while complying legal obligations & developing business opportunities"
            }
          />

          <section className="container service-cards-carousel-container">
            <div className="row">
              <h3
                className="white-text section-title"
                style={{ fontWeight: "700", marginLeft: "40px" }}
              >
                Nuestros servicios
              </h3>
            </div>

            {/* <ServiceCardsCarousel /> */}

            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="col-lg-3 col-sm-6 col-12 nuestros-servicios-card mb-15">
                <TransparentContainer
                  height="100%"
                  width="100%"
                  usePadding={false}
                  hideOverflow={true}
                  justifyContent={false}
                >
                  <div style={{ width: "100%" }}>
                    <img
                      className="nuestros-servicios-img"
                      src="assets/images/weconnect/nuestros-servicios-1.png"
                      alt=""
                    />
                  </div>
                  <div
                    style={{ width: "100%", padding: "18px 18px", flex: "1" }}
                  >
                    <div className="text-center">
                      <h3 className="white-text mb-5">
                        Tecnología <br /> Aplicada
                      </h3>
                    </div>
                    <div className="mb-10">
                      <p className="fs-14 mb-1">
                        <span className="misc-dot">&#183;</span> Diseño y
                        desarrollo de soluciones.
                      </p>
                      <p className="fs-14 mb-1">
                        <span className="misc-dot">&#183;</span> Despliegue IA.
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "100%", padding: "18px 18px" }}>
                    <TransparentContainer width="100%">
                      <a
                        className="no-href-deco white-text"
                        href="/tecnologia-aplicada"
                      >
                        Quiero saber más
                      </a>
                    </TransparentContainer>
                  </div>
                </TransparentContainer>
              </div>

              <div className="col-lg-3 col-sm-6 col-12 nuestros-servicios-card mb-15">
                <TransparentContainer
                  height="100%"
                  width="100%"
                  usePadding={false}
                  hideOverflow={true}
                  justifyContent={false}
                >
                  <div style={{ width: "100%" }}>
                    <img
                      className="nuestros-servicios-img"
                      src="assets/images/weconnect/nuestros-servicios-2.png"
                      alt=""
                    />
                  </div>
                  <div
                    style={{ width: "100%", padding: "18px 18px", flex: "1" }}
                  >
                    <div className="text-center">
                      <h3 className="white-text mb-5">Data Analytics</h3>
                    </div>
                    <div className="mb-10">
                      <p className="fs-14 mb-1">
                        <span className="misc-dot">&#183;</span> Optimización de
                        decisiones basadas en datos.
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "100%", padding: "18px 18px" }}>
                    <TransparentContainer width="100%">
                      <a
                        className="no-href-deco white-text"
                        href="/data-analytics"
                      >
                        Quiero saber más
                      </a>
                    </TransparentContainer>
                  </div>
                </TransparentContainer>
              </div>

              <div className="col-lg-3 col-sm-6 col-12 nuestros-servicios-card mb-15">
                <TransparentContainer
                  height="100%"
                  width="100%"
                  usePadding={false}
                  hideOverflow={true}
                  justifyContent={false}
                >
                  <div style={{ width: "100%" }}>
                    <img
                      className="nuestros-servicios-img"
                      src="assets/images/weconnect/nuestros-servicios-3.png"
                      alt=""
                    />
                  </div>
                  <div
                    style={{ width: "100%", padding: "18px 18px", flex: "1" }}
                  >
                    <div className="text-center">
                      <h3 className="white-text mb-5">
                        Estrategia <br /> Empresarial
                      </h3>
                    </div>
                    <div className="mb-10">
                      <p className="fs-14 mb-1">
                        <span className="misc-dot">&#183;</span> Investigación
                        de mercado.
                      </p>
                      <p className="fs-14 mb-1">
                        <span className="misc-dot">&#183;</span> Desarrollo
                        Mercados internacionales.
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "100%", padding: "18px 18px" }}>
                    <TransparentContainer width="100%">
                      <a
                        className="no-href-deco white-text"
                        href="/estrategia-empresarial"
                      >
                        Quiero saber más
                      </a>
                    </TransparentContainer>
                  </div>
                </TransparentContainer>
              </div>

              <div className="col-lg-3 col-sm-6 col-12 nuestros-servicios-card mb-15">
                <TransparentContainer
                  height="100%"
                  width="100%"
                  usePadding={false}
                  hideOverflow={true}
                  justifyContent={false}
                >
                  <div style={{ width: "100%" }}>
                    <img
                      className="nuestros-servicios-img"
                      src="assets/images/weconnect/nuestros-servicios-4.png"
                      alt=""
                    />
                  </div>
                  <div
                    style={{ width: "100%", padding: "18px 18px", flex: "1" }}
                  >
                    <div className="text-center">
                      <h3 className="white-text mb-5">
                        Industrias <br /> Emergentes
                      </h3>
                    </div>
                    <div className="mb-10">
                      <p className="fs-14 mb-1">
                        <span className="misc-dot">&#183;</span> Consultorías
                        especializadas.
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "100%", padding: "18px 18px" }}>
                    <TransparentContainer width="100%">
                      <a
                        className="no-href-deco white-text"
                        href="/industrias-emergentes"
                      >
                        Quiero saber más
                      </a>
                    </TransparentContainer>
                  </div>
                </TransparentContainer>
              </div>
            </div>
          </section>

          <div className="misc-separator-line mb-10" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div>
                    <h1 className="white-text section-title">Caso de éxito</h1>

                    <p className="white-text">
                      TPX, nuestro último caso de éxito. La solución de soporte
                      operativo para la gestión de servicios intragrupo.
                    </p>

                    <a
                      className="white-text no-href-deco"
                      href="//tpxtreme.com"
                      target="_blank"
                    >
                      Ir a TPX <i class="fa fa-arrow-right white-text"></i>
                    </a>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <TransparentContainer width="100%" height="100%">
                    <div className="blue-container">
                      <img
                        className="tpx-landing-img"
                        src="assets/images/weconnect/tpx-landing-mini.png"
                        alt=""
                      />
                    </div>
                  </TransparentContainer>
                </div>
              </div>
            </div>
          </section>

          <div className="misc-separator-line mb-10" />

          <section className="we-are-global-section">
            <div className="container text-center">
              <h1 className="white-text section-title">Somos globales</h1>
              <img
                className="planet-img"
                src="assets/images/weconnect/planet.svg"
                alt=""
              />
            </div>
          </section>
        </div>

        <a href="#" id="toTop"></a>

        {/* <div id="preloader">
				<div className="inner">
					<span className="loader"></span>
				</div>
			  </div> */}
      </body>
    </div>
  );
};

export default Home;
