import React from "react";

const TransparentContainer = ({
  children,
  width = "min-content",
  height = "min-content",
  usePadding = true,
  hideOverflow = false,
  justifyContent = true,
  center = true,
  flexDirection,
  className,
}) => {
  return (
    <div
      className={`transparent-container ${!usePadding ? "no-padding" : ''} ${hideOverflow ? "overflow-hidden" : ''} ${!justifyContent ? 'no-justify' : ''} ${!center ? 'no-center' : ''} ${flexDirection === 'row' ? 'flex-row' : ''} ${className}`}
      style={{ width, height }}
    >
      {children}
    </div>
  );
};

export default TransparentContainer;
