import React, { useEffect } from "react";
import SocialIcon from "./social-icon";
// import * as language from '../i18n.js';

// import logo from '../logo.svg';

const Footer = () => {

  return (
    <footer id="footer">
      <div className="container">
        <div
          className="row"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div className="col-lg-4 col-12 text-center icon-and-socials-footer-container mb-15">
            <div className="row">
              <div className="col-8 offset-2 col-md-6 offset-md-3 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 offset-lg-0 mb-0">
                <img
                  className="footer-logo mb-30"
                  src="assets/images/weconnect/footer-logo.svg"
                  alt=""
                />

                <div className="mb-15 row">
                  {/* <SocialIcon iconName={"facebook"} />
                  <SocialIcon iconName={"instagram"} /> */}
                  <SocialIcon iconName={"linkedin"} link={'https://www.linkedin.com/company/we-connect-global-advisors/?viewAsMember=true'}/>
                  {/* <SocialIcon iconName={"youtube"} /> */}
                </div>

                <h3 className="black-text footer-contact-email">contact@we-connect.global</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-6 offset-lg-2">
            <div className="row">
              <div className="col-8 offset-2 col-md-6 offset-md-3 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 offset-lg-0 mb-0">
                <div className="row">
                <div className="col-lg-6 col-12 mb-15">
                <address>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <strong>Lima, Perú</strong>
                    </li>
                    <li className="mb-10">
                      Calle Los Tucanes 274 Int. 502, San Isidro, Lima
                    </li>
                    <li>
                      + 51 9935 - 25102
                      <br />
                      +51 9935 - 01853
                    </li>
                  </ul>
                </address>
              </div>
              <div className="col-lg-6 col-12 mb-15">
                <address>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <strong>Buenos Aires, Argentina</strong>
                    </li>
                    <li className="mb-10">
                      H. Yrigoyen 1565 Vicente López BsAs, Argentina
                    </li>
                    <li>
                      + 54 911 3 9456 4789
                    </li>
                  </ul>
                </address>
              </div>
              <div className="col-lg-6 col-12 mb-15">
                <address>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <strong>Miami, United States</strong>
                    </li>
                    <li className="mb-10">
                    8175 NW 23th St. Suite 120 Doral, Fl 33126
                    </li>
                    <li>
                      + 51 9935 - 01853
                    </li>
                  </ul>
                </address>
              </div>
              <div className="col-lg-6 col-12 mb-15">
                <address>
                  <ul className="list-unstyled">
                    <li className="mb-1">
                      <strong>European Union Headquartes</strong>
                    </li>
                    <li className="mb-10">
                    90 Rue du general Roguet 92110 - France
                    </li>
                    <li>
                      + 33 6600 45104
                    </li>
                  </ul>
                </address>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      {/* <div className="copyright">
        <div className="container">
          <ul className="float-right m-0 list-inline mobile-block">
            <li>
              <a href="#">Terms &amp; Conditions</a>
            </li>
            <li>&bull;</li>
            <li>
              <a href="#">Privacy</a>
            </li>
          </ul>
          &copy; All Rights Reserved, Coding Software LLC
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
