import React, { useEffect, useState } from "react";
// import * as language from '../i18n.js';

// import logo from '../logo.svg';

import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import TransparentContainer from "./transparent-container.js";
import $ from "jquery";
import WhiteStrip from "./white-strip.js";
import IconizedText from "./iconized-text.js";
import ServiceCardsCarousel from "./service-cards.js";
import IconizedCardContainer from "./iconized-card-container.js";
import Modal from 'react-modal';

const Contact = () => {

  const handleSelect = (eventKey) => console.log(`selected ${eventKey}`);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'white',
      borderRadius: '20px',
      zIndex: '100000',
      maxHeight: '80%',
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (member) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const submitForm = (e) => {
    e.preventDefault();

    var formData = $("form").serializeArray();

    $.ajax({
      async: true,
      type: "POST",
      url: "http://larry.tecdata/weconnect/php/contact.php",
      data: formData,
      dataType: "json",
      encode: true,
  }).done(function (data) {
    console.log(data);

      if (data.code !== 200) {
          if (data.msg) {
              // $('#form-status').append(
              //     '<span class="errorMsg">' + data.msg + '(' + data.code + ')' + "</span>"
              // )
              console.log('success')
          }
      } else {
          // modal.style.display = "block";

          // $('#form-status').append(
          //     '<span class="successMsg"> Message sent! </span>'
          // )

          console.log('error')
      }
  });
  }

  return (
    <div>
      <body className="smoothscroll enable-animation">
        <div id="wrapper">

          <section className="standalone-form-misc-wave contact-form">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12 flex-center">
                  <h2 className="white-text section-title">
                    Llena tus datos y <br /> te contactaremos
                  </h2>
                </div>
                <div className="col-md-6 col-12">
                  <form
                    action="/php/contact.php"
                    method="post"
                    enctype="multipart/form-data"
                    id="contactForm"
                    onSubmit={(e) => submitForm(e)}
                  >
                    <div class="row">
                      <div class="col-12 mb-20">
                        <input
                          required
                          type="text"
                          class="form-control"
                          name="contact[name]"
                          id="input-name"
                          placeholder="Nombre y apellido"
                        />
                      </div>
                      <div class="col-12 mb-20">
                        <input
                          required
                          type="email"
                          class="form-control"
                          name="contact[email]"
                          id="input-email"
                          placeholder="Correo"
                        />
                      </div>
                      <div class="col-12 mb-20">
                        <input
                          type="position"
                          class="form-control"
                          name="contact[position]"
                          id="input-position"
                          placeholder="Puesto"
                        />
                      </div>

                      <div class="col-12 mb-20">
                        <input
                          type="companyName"
                          class="form-control"
                          name="contact[companyName]"
                          id="input-companyName"
                          placeholder="Nombre de tu empresa"
                        />
                      </div>

                      <div class="col-12 mb-20">
                        <input
                          type="country"
                          class="form-control"
                          name="contact[country]"
                          id="input-country"
                          placeholder="País"
                        />
                      </div>

                      {/* <div class="col-12 mb-20">
                        <select
                          class="form-control pointer"
                          name="contact[userAmnt]"
                          id="input-userAmnt"
                        >
                          <option disabled selected hidden value="">
                            Cantidad de usuarios
                          </option>
                          <option value="1-4">1-4</option>
                          <option value="5-19">5-19</option>
                          <option value="20-99">20-99</option>
                          <option value="100+">100+</option>
                        </select>
                      </div> */}

                      <div class="col-12 mb-20">
                        <textarea
                          maxlength="10000"
                          rows="2"
                          class="form-control"
                          name="contact[message]"
                          id="input-message"
                          placeholder="Mensaje"
                        ></textarea>
                      </div>

                      <div class="col-12 mb-20">
                        <TransparentContainer width="100%">
                          <button className="no-href-deco white-text" href="#" onClick={openModal}>
                            Enviar
                          </button>
                        </TransparentContainer>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        style={customStyles}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              onClick={() => {
                closeModal();
              }}
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 text-center mb-20">
                <i class="fa fa-check-circle-o contact-confirmation-icon fs-200" aria-hidden="true"></i>
              </div>
              <div className="col-12 text-center">
                <h2><b>Enviaste tus datos</b></h2>
                <h3>Nos comunicaremos contigo para esclarecer tus dudas.</h3>
              </div>
            </div>
          </div>
        </div>
      </Modal>

        <a href="#" id="toTop"></a>

        {/* <div id="preloader">
				<div className="inner">
					<span className="loader"></span>
				</div>
			  </div> */}
      </body>
    </div>
  );
};

export default Contact;
