import React from 'react';

const SocialIcon = ({ iconName, link }) => {
  return (
    <div className='col-12 mb-0'>
      <div className="social-icon-container text-center">
        <a href={link}>
          <img className='social-icons' src={`assets/images/weconnect/icons/${iconName}.svg`} alt="" />
        </a>
      </div>
    </div>
  );
};

export default SocialIcon;